import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Section from "../components/section";
import { H2 } from '../css/stylecomp'
import Footer from "../components/footer";

function SuccessPage() {
  return (
    <Layout>
      <SEO
        title="Contact"
        keywords={[`data`, `science`, `consulting`]}
      />
      <div>
        <Section className="bg-very-lightest-grey border-solid border border-very-grey-light h-auto pt-8 pb-8 flex items-center" containerClass="">
          <div className="flex flex-col lg:w-full text-center py-16">
            <H2 className="font-thin ">Thanks for getting in touch! We'll get back to you asap... </H2>
          </div>
        </Section>
        <Footer />
      </div>
    </Layout>
  )
}

export default SuccessPage;
